// Title
$section-title-font: #{rem-calc(32)} / 1 $font-secondary;
$section-title-font--large: #{rem-calc(38)} / 1.25 $font-secondary;
$section-title-text-transform: none;
$section-title-text-transform--large: none;

// Secondary title
$section-secondary-title-font: #{rem-calc(32)} / 1 $font-secondary;
$section-secondary-title-font--large: $section-secondary-title-font;

//View more
$section-view-more-link-font--large: #{rem-calc(14)} / 1.25 $font-primary;

// Headings
$section-headings-max-width--large: 100%;

// View more
$section-view-more-link-font: #{rem-calc(14)} / rem-calc(20) $font-primary;
$section-view-more-link-font--large: $section-view-more-link-font;
$section-view-more-link-text-transform: none;

// layout
$section-content-margin--large: rem-calc(16 0 0);

// description
$section-description-font: #{rem-calc(14)} / rem-calc(20) $font-primary;
$section-description-font--large: $section-description-font;

@import "@lora/05-components/section";