// Text Suggestions
$header-suggestions-text-font: #{rem-calc(14)} / rem-calc(20) $font-primary;
$header-suggestions-text-font--large: $header-suggestions-text-font;

// Suggestions Title
$header-suggestions-title-font: 500 #{rem-calc(14)} / rem-calc(16) $font-primary;
$header-suggestions-title-font--large: rem-calc(32) $font-secondary;
$header-suggestions-text-link-padding: rem-calc(0 0 0 20);
$header-suggestions-title-padding: rem-calc(20 0 0 20);
$header-suggestions-title-text-transform: uppercase;

// Product Suggestions
$header-suggestions-products-name-text-transform--large: uppercase;
$header-suggestions-products-link-padding: rem-calc(0 0 0 20);

@import "@lora/05-components/header/header-suggestions";