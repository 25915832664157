// Title
$content-hero-title-font: #{rem-calc(32)} / 1 $font-secondary;
$content-hero-title-font--large: #{rem-calc(38)} / 1.25 $font-secondary;
$content-hero-title-text-transform: none;
$content-hero-title-text-transform--large: none;
$content-hero-title-color: color(dark);

// Description
$content-hero-description-margin: rem-calc(0 0 45);
$content-hero-description-margin--large: $content-hero-description-margin;
$content-hero-description-color: color(dark);
$content-hero-description-font: 100 #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$content-hero-description-font--large: #{rem-calc(15)} / #{rem-calc(20)} $font-secondary;

// label
$content-hero-label-color: color(dark);
$content-hero-label-color--large: $content-hero-label-color;
$content-hero-label-font: 500 #{rem-calc(12)} / #{rem-calc(18)} $font-tertiary;
$content-hero-label-background-padding: rem-calc(5 12);

@import "@lora/05-components/content/content-hero";