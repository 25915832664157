$form-text-field-radius: 0;
$form-text-field-border: 0;
$form-text-field-border-hover: 0;
$form-text-field-border-focus: 0;
$form-text-field-box-shadow: rem-calc(0 2 0 0) color(organism-background);
$form-text-field-box-shadow-hover: $form-text-field-box-shadow;
$form-text-field-box-shadow-focus: $form-text-field-box-shadow;
$form-text-field-padding: rem-calc(0);
$form-text-field-height: rem-calc(50);
$form-text-field-font-size: rem-calc(14);
$form-text-field-line-height: 1.43;
$form-text-field-color-hover: color(text);
$form-text-field-textarea-min-height: auto;

/// On error
$form-text-field-requirements-item-error-icon: 'error-icon';

$form-text-field-requirements-item-icon-margin: rem-calc(0 8 0 0);

@import "@lora/05-components/form/text-field";