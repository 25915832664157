// Label
$content-tile-label-display: inline-block;
$content-tile-label-display--large: $content-tile-label-display;
$content-tile-label-background: color(light);
$content-tile-label-background--large: $content-tile-label-background;
$content-tile-label-padding: rem-calc(5 10);
$content-tile-label-padding--large: rem-calc(5 10);

// Title
$content-tile-title-font-size: rem-calc(14);
$content-tile-title-font-size--large: rem-calc(18);
$content-tile-title-line-height: 1.25;
$content-tile-title-line-height--large: $content-tile-title-line-height;
$content-tile-title-font: #{$content-tile-title-font-size} / $content-tile-title-line-height $font-secondary;
$content-tile-title-font--large: #{$content-tile-title-font-size--large} / $content-tile-title-line-height--large $font-secondary;
$content-tile-title-text-transform: none;
$content-tile-title-text-transform--large: $content-tile-title-text-transform;
$content-tile-equal-rows-title-height: rem-calc(20);
$content-tile-equal-rows-title-height--large: $content-tile-equal-rows-title-height;

// Description
$content-tile-description-font-size: rem-calc(14);
$content-tile-description-font-size--large: $content-tile-description-font-size;
$content-tile-description-line-height: 1.5;
$content-tile-description-line-height--large: $content-tile-description-line-height;
$content-tile-description-font: #{$content-tile-description-font-size} / $content-tile-description-line-height $font-primary;
$content-tile-description-font--large: #{$content-tile-description-font-size--large} / $content-tile-description-line-height--large $font-primary;
$content-tile-title-margin: rem-calc(0 0 20);

// Label Large
$content-tile-large-label-text-transform: uppercase;
$content-tile-large-label-text-transform--large: $content-tile-large-label-text-transform;
$content-tile-large-label-margin: rem-calc(0 0 15);

// Title Large
$content-tile-large-title-text-transform: none;
$content-tile-large-title-text-transform--large: $content-tile-large-title-text-transform;
$content-tile-large-title-font: 500 #{rem-calc(32)} / rem-calc(32) $font-secondary;
$content-tile-large-title-font--large: 500 #{rem-calc(38)} / rem-calc(40) $font-secondary;
$content-tile-title-margin: rem-calc(0 0 20);
$content-tile-title-margin--large: $content-tile-title-margin;

// Description Large
$content-tile-large-description-font: #{rem-calc(14)} / rem-calc(20) $font-primary;
$content-tile-large-description-font--large: $content-tile-large-description-font;
$content-tile-large-description-margin: rem-calc(0 0 35);

@import "@lora/05-components/content-tile";