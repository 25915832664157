$footer-social-title-font--large: 300 #{rem-calc(15)} / 1.33 $font-secondary;
$footer-social-link-color: color(primary);
$footer-social-link-border-radius: 50%;
$footer-social-link-border: 1px solid color(light);
$footer-social-link-hover-border: 1px solid color(primary-active);
$footer-social-link-background: color(light);
$footer-social-list-margin: rem-calc(29 27 35);
$footer-social-list-margin--large: rem-calc(25 0 0);
$footer-social-list: (
    facebook: (
        enabled: true,
        icon: facebook,
        icon-height: rem-calc(16),
        icon-width: rem-calc(16)
    ),
    twitter: (
        enabled: true,
        icon: twitter,
        icon-height: rem-calc(16),
        icon-width: rem-calc(16)
    ),
    google: (
        enabled: true,
        icon: google,
        icon-height: rem-calc(16),
        icon-width: rem-calc(16)
    ),
    pinterest: (
        enabled: true,
        icon: pinterest,
        icon-height: rem-calc(16),
        icon-width: rem-calc(16)
    ),
    instagram: (
        enabled: true,
        icon: instagram,
        icon-height: rem-calc(16),
        icon-width: rem-calc(16)
    ),
    youtube: (
        enabled: true,
        icon: youtube,
        icon-height: rem-calc(16),
        icon-width: rem-calc(16)
    ),
);
$footer-social-link-padding: rem-calc(6);
$footer-social-list-justify-content: flex-start;
$footer-social-list-justify-content--large: $footer-social-list-justify-content;
$footer-social-item-margin: rem-calc(0 20 0 0);
$footer-social-item-margin--large: $footer-social-item-margin;
$footer-social-link-hover-color: color(primary-active);

@import "@lora/05-components/footer/footer-social";