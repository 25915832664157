// Minicart global style
$minicart-background: none;
$minicart-subtotal-background: none;

// Empty minicart
$minicart-empty-width: rem-calc(670);
$minicart-empty-content-background: color(global-background);
$minicart-empty-text-font: #{rem-calc(38)} / rem-calc(40) $font-secondary;
$minicart-empty-text-margin: rem-calc(0 0 35);
$minicart-empty-content-width: 55%;
$minicart-empty-content-max-width: $minicart-empty-content-width;
$minicart-empty-recommendation-width: 45%;
$minicart-empty-background: color(global-background);

// Minicart Table
$minicart-th-details-font: #{rem-calc(32)} / 1 $font-secondary;
$minicart-th-padding: rem-calc(70 15 15);
$minicart-th-details-padding: rem-calc(70 15 15 100);
$minicart-th-detail-text-transform: none;
$minicart-th-price-padding: rem-calc(70 100 15 15);
$minicart-td-thumbnail-width: rem-calc(270);
$minicart-td-thumbnail-padding: rem-calc(5 15 5 100);
$minicart-td-vertical-align: top;
$minicart-td-price-padding: rem-calc(0 100 10 15);

// Minicart Product
$minicart-product-title-font: 500 #{rem-calc(14)} / rem-calc(16) $font-primary;
$minicart-product-title-margin: rem-calc(0 0 15);

// Product Remove Icon
$minicart-remove-icon-display: false;
$minicart-remove-text-transform: capitalize;
$minicart-remove-font: #{rem-calc(12)} / rem-calc(18) $font-primary;
$minicart-remove-hover-color: color(secondary-active);

// Minicart Actions
$minicart-actions-background: none;
$minicart-actions-padding: rem-calc(30 100);
$minicart-header-border: 1px solid color(border);
$minicart-footer-border: none;
$minicart-footer-content-background: color(global-background);

// Minicart Subtotal
$minicart-subtotal-title-padding: rem-calc(15 30 15 100);
$minicart-subtotal-price-padding: rem-calc(15 100 15 15);
$minicart-subtotal-title-text-transform: uppercase;

@import "@lora/05-components/minicart";