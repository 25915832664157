@import "@root/05-components/loyalty/loyalty-progress";
@import "loyalty-progress-hr";

$loyalty-progress-tier-header-current-font: 700 #{rem-calc(12)} / 1.45 $font-primary;
$loyalty-progress-tier-header-current-font--large: 700 #{rem-calc(14)} / 1.45 $font-primary;
$loyalty-progress-tier-header-font--large: 500 #{rem-calc(14)} / 1.45 $font-primary;
$loyalty-progress-tier-title-font: 500 #{rem-calc(14)} / 1.45 $font-primary;
$loyalty-progress-tier-title-font--large: 500 #{rem-calc(14)} / 1.45 $font-primary;
$loyalty-progress-tier-title-current-font: 700 #{rem-calc(14)} / 1.45 $font-primary;
$loyalty-progress-tier-title-current-font--large: 700 #{rem-calc(14)} / 1.45 $font-primary;
$loyalty-progress-tier-subtitle-font: 500 #{rem-calc(14)} / 1.45 $font-primary;
$loyalty-progress-tier-subtitle-font--large: 500 #{rem-calc(14)} / 1.45 $font-primary;
$loyalty-progress-tier-subtitle-current-font: 700 #{rem-calc(14)} / 1.45 $font-primary;
$loyalty-progress-tier-subtitle-current-font--large: 700 #{rem-calc(14)} / 1.45 $font-primary;
$loyalty-progress-tier-tooltip-height: rem-calc(115);
$loyalty-progress-bar-wrapper-margin-bottom: $loyalty-progress-tier-tooltip-height + rem-calc(30);

@include jphrlayer-components-loyalty-progress;
