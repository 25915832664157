$button-disabled-background: color(disabled);
$button-disabled-color: color(light);
$button-hover-color: color(primary);
$button-loading-icon-hover-color: color(dark);
$button-social-icon-size: rem-calc(30);
$button-social-icon-left: false;
$button-social-icon-margin: auto;

$button-padding: rem-calc(18 12);
$button-radius: rem-calc(30);
$button-font-weight: 500;

$button-secondary: (
    background: color(secondary),
    background-hover: color(primary-active),
    color: color(dark),
    color-hover: color(dark),
    border: 1px solid color(dark),
    border-hover: 1px solid color(primary-active)
);

$button-palette: (
    secondary: $button-secondary,
    alert: $button-secondary
);

// Social buttons
$button-social-list: (
    facebook: (
        icon: facebook-2,
        background: color(dark),
        background-hover: color(primary-active),
        color: color(light),
        color-hover: color(dark),
        icon-color: color(light),
        icon-color-hover: color(dark),
        border: null,
        border-hover: null
    ),
    google: (
        icon: google-2,
        background: color(dark),
        background-hover: color(primary-active),
        color: color(light),
        color-hover: color(dark),
        icon-color: color(light),
        icon-color-hover: color(dark),
        border: null,
        border-hover: null
    )
);

$button-print-text-decoration: none;
$button-min-width: rem-calc(180);
$button-search-height: rem-calc(50);
$button-icon-only-height: rem-calc(50);
$button-row-2-price-font: 500 #{rem-calc(12)} / #{rem-calc(18)} $font-tertiary;
$button-row-2-text-font: 500 #{rem-calc(12)} / #{rem-calc(18)} $font-tertiary;

@import "@lora/05-components/button";