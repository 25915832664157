@mixin jphrlayer-components-loyalty-progress {
    .c-loyalty-progress__tier-bar-wrapper {
        &.l-row {
            @include breakpoint(medium down) {
                overflow: visible;
            }
        }

        .c-loyalty-progress__tier-tooltip {
            @include breakpoint(medium down) {
                max-width: 95vw;
                width: 100vw;
                height: rem-calc(150);
                left: -44vw;
                right: 0;
            }
    
            &.m-first {
                @include breakpoint(medium down) {
                    left: -24vw;
                }
            }

            &.m-last {
                @include breakpoint(medium down) {
                    left: -83vw;
                }
            }
        }

        .c-loyalty-progress__bar-start-point:nth-child(4) {
            .c-loyalty-progress__tier-tooltip {
                @include breakpoint(medium down) {
                    left: -63vw;
                }
            }
        }

        .c-loyalty-progress__bar-wrapper {
            &:not(.m-empty) {
                @include breakpoint(medium down) {
                    margin-bottom: rem-calc(180);
                }
            }
        }
    }
}
