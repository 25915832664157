$footer-background: color(primary);
$footer-background--large: $footer-background;
$footer-top-background: color(element-background);
$footer-middle-inner-padding--large: rem-calc(0);
$footer-bottom-inner-border-top--large: rem-calc(1) solid color(border);
$footer-bottom-border-top--large: none;
$footer-row-variants-flex-direction: column-reverse;
$footer-row-item-last-padding-left: 0;
$footer-bottom-inner-display: block;
$footer-row-item-margin: rem-calc(0 0 5);
$footer-row-variants-margin: rem-calc(0 12 15);
$footer-newsletter-signup-title-display: none;
$footer-newsletter-signup-title-display--large: block;
$footer-newsletter-signup-title-font: #{rem-calc(15)} $font-secondary;
$footer-newsletter-link-hover-color: color(secondary);
$footer-newsletter-captcha-link-hover-color: color(secondary);

@import "@lora/04-layout/footer";