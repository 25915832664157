$header-navigation-list-level-2-margin--large: 0;
$header-navigation-item-level-2-all-link-text-decoration: underline;
$header-navigation-item-level-3-all-link-text-decoration: underline;
$header-navigation-link-level-1-font-size--large: rem-calc(14);
$header-navigation-link-level-1-font-weight--large: 500;
$header-navigation-link-level-1-font-size: rem-calc(14);
$header-navigation-link-level-2-font-size: rem-calc(14);
$header-navigation-link-level-1-text-transform--large: uppercase;
$header-navigation-link-level-1-text-transform: uppercase;
$header-navigation-link-level-2-hover-font-weight: 500;
$header-navigation-item-title-level-1-padding-left: rem-calc(16);
$header-navigation-item-title-level-1-expanded-background: color(light);
$header-navigation-item-title-level-1-expanded-color: color(dark);
$header-navigation-item-title-level-2-font-weight: normal;
$header-navigation-item-title-level-2-font-weight--large: normal;
$header-navigation-item-title-level-2-background: color(category-background);
$header-navigation-item-title-level-2-padding-left: rem-calc(24);
$header-navigation-item-title-padding: rem-calc(16 0);
$header-navigation-item-title-level-2-expanded-background: color(category-background);
$header-navigation-item-title-level-2-expanded-color: color(dark);
$header-navigation-item-title-level-3-background: color(category-background);
$header-navigation-item-level-2-padding--large: rem-calc(0 0 5);
$header-navigation-link-level-2-text-transform--large: uppercase;
$header-navigation-link-level-2-font-size--large: rem-calc(14);
$header-navigation-item-level-3-border: none;
$header-navigation-link-level-3-font-weight: 400;
$header-navigation-link-level-3-font-size: rem-calc(14);
$header-navigation-link-level-3-font-size--large: rem-calc(14);
$header-navigation-link-level-3-padding--medium-down: rem-calc(11 0 11 48);
$header-navigation-link-level-3-color: color(element);
$header-navigation-link-level-3-hover-font-weight: 500;
$header-navigation-link-level-3-hover-color: color(element);
$header-navigation-item-level-3-all-link-color: color(element);
$header-navigation-item-level-3-all-link-text-decoration--large: none;
$header-navigation-item-level-3-all-link-font: #{rem-calc(14)} / #{rem-calc(16.8)} $font-primary;
$header-navigation-item-level-3-all-link-hover-color: color(element);
$header-navigation-list-level-3-margin--large: rem-calc(7 0 0);
$header-navigation-flyout-element-border-top--large: 1px solid color(border);
$header-navigation-flyout-element-background--large: color(light);
$header-navigation-flyout-element-margin-left--large: rem-calc(-23);
$header-navigation-link-level-1-padding--large: rem-calc(17 16 18);
$header-navigation-link-level-1-hover-font-weight: 500;
$header-navigation-link-level-1-hover-color: color(text-secondary);
$header-navigation-link-level-1-hover-color--large: $header-navigation-link-level-1-hover-color;
$header-navigation-container-content-padding: rem-calc(8 16 16);
$header-navigation-item-title-level-1-expanded-font: 700 #{rem-calc(14)} / #{rem-calc(17)} $font-primary;
$header-navigation-item-title-level-2-expanded-font: 700 #{rem-calc(14)} / #{rem-calc(17)} $font-primary;
$header-navigation-item-title-level-1-font--large: #{rem-calc(14)} / #{rem-calc(16.8)} $font-primary;
$header-navigation-container-grid-padding--xlarge: rem-calc(24 0);
$header-navigation-item-title-level-2-font--large: #{rem-calc(14)} / #{rem-calc(16.8)} $font-primary;
$header-navigation-item-level-3-padding--large: rem-calc(5 0 7 0);
$header-navigation-link-level-3-font: #{rem-calc(14)} / #{rem-calc(16.8)} $font-primary;

@import "@lora/05-components/header/header-navigation";