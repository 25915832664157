$form-check-field-radio-control-size: rem-calc(43);
$form-check-field-radio-checked-control-border-width: #{$form-check-field-radio-control-size / 6};
$form-check-field-radio-checked-control-font-weight: 400;
$form-check-field-label-font-size: rem-calc(12);
$form-check-field-checkbox-checked-control-font-weight: 500;
$form-check-field-checkbox-control-border: 1px solid color(organism-background);
$form-check-field-checkbox-checked-control-background: color(light);
$form-check-field-checkbox-checked-control-border: 1px solid color(organism-background);
$form-check-field-checkbox-checked-icon-color: color(dark);
$form-check-field-checkbox-checked-icon: check-alt;
$form-check-field-disabled-control-background: transparent;
$form-check-field-checkbox-checked-icon-width: rem-calc(23);
$form-check-field-radio-checked-control-border-after: rem-calc(0.6) solid color(primary);
$form-check-field-radio-control-after-top: 0;
$form-check-field-radio-control-after-size: rem-calc(20);
$form-check-field-radio-control-margin-top: 0;
$form-check-field-image-checked-label-border: rem-calc(2) solid color(dark);
$form-check-field-switch-after-position--top: rem-calc(0);
$form-check-field-switch-padding: rem-calc(0 60 0 0);
$form-check-field-switch-before-border-radius: rem-calc(15);

// Switch before
$form-check-field-switch-before-background: color(element-background);
$form-check-field-switch-before-width: rem-calc(32);
$form-check-field-switch-checked-before-background: color(text-secondary);

// Switch after
$form-check-field-switch-after-background: color(primary);
$form-check-field-switch-after-height: rem-calc(14);
$form-check-field-switch-after-width: rem-calc(14);
$form-check-field-switch-checked-after-background: color(results-background);
$form-check-field-switch-after-position--top: rem-calc(2.2);
$form-check-field-switch-checked-after-position--right: rem-calc(2.46);

@import "@lora/05-components/form/check-field";