$header-sticked-background: color(results-background);
$header-search-button-background--large: color(element-background);
$header-search-button-color--large: color(dark);
$header-search-button-icon-color--large: color(dark);
$header-search-button-padding--large: rem-calc(16);
$header-search-button-label-margin: rem-calc(0 0 0 8);
$header-search-button-icon-height: rem-calc(20);
$header-search-button-icon-width: rem-calc(20);
$header-search-button-boder-radius--large: rem-calc(50);
$header-search-cta-order: 1;
$header-top-nav-item-separator-color: transparent;
$header-top-nav-item-font-size--large: rem-calc(12);
$header-top-nav-item-font-weight--large: 300;
$header-top-nav-item-color: color(dark);
$header-top-nav-item-hover-color: color(text-secondary);
$header-sticked-logo-max-width--large: rem-calc(85);
$header-sticked-search-button-margin--large: rem-calc(3 -25) 0 0;
$header-localization-order: 2;
$header-logo-text-align: center;
$header-logo-order: 3;
$header-logo-max-width: rem-calc(61);
$header-sticked-logo-max-width: rem-calc(61);
$header-logo-max-width--large: rem-calc(79);
$header-main-area-padding: rem-calc(0 0 0 16);
$header-main-area-padding--large: rem-calc(5 0 0 16);
$header-sticked-position--large: relative;
$header-sticked-border-bottom: 1px solid color(border);
$header-border-bottom: 1px solid color(global-background);
$header-sticked-minicart-icon-color: color(text-secondary);
$header-search-button-icon-active-height: $header-search-button-icon-height;
$header-search-button-icon-active-width: $header-search-button-icon-width;
$header-user-link-account-sticky-login-icon: user;
$header-user-link-account-sticky-login-icon-hover-color: color(primary-active);
$header-search-button-background-hover--large: color(dark);
$header-search-button-color-hover--large: color(light);
$header-search-button-icon-color-hover--large: color(light);
$header-search-button-border--large: 1px solid transparent;
$header-search-button-active-border--large: 1px solid color(dark);
$header-is-full-width: true;
$header-top-nav-signup-text-display: none;
$header-top-nav-signup-icon-display: block;
$header-sticked-top-nav-item-level-1-max-width--large: rem-calc(140);
$header-hamburger-border--large: none;

// Active search button
$header-search-button-active-after-border: none;

@import "@lora/04-layout/header";

@mixin master-layout-header {
    .l-header.m-sticked {
        .l-header__top-nav-item.m-stores {
            @include breakpoint(large) {
                display: none;
            }
        }
    }
}