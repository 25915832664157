// Tabs main
$tabs-tab-font: #{rem-calc(14)} / 1 $font-primary;
$tabs-tab-text-transform: uppercase;
$tabs-tab-text-transform--large: $tabs-tab-text-transform;
$tabs-tab-after-border-bottom: 2px solid color(border);
$tabs-tab-after-width: calc(100% + 1px);
$tabs-tab-margin: 0;
$tabs-tab-margin--large: 0;
$tabs-tab-font--large: #{rem-calc(20)} / 1 $font-secondary;
$tabs-tab-active-font--large: #{rem-calc(32)} / 1 $font-secondary;
$tabs-tab-hover-bottom-border-color: color(dark);
$tabs-tab-hover-bottom-border-width: 100%;

// Tabs secondary
$tabs-secondary-tab-font-size--large: rem-calc(18);
$tabs-tab-active-color: color(primary);
$tabs-secondary-tab-line-height--large: rem-calc(20);
$tabs-secondary-tab-text-transform: capitalize;
$tabs-tab-active-after-border-bottom: 2px solid color(primary);

// Tabs tertiary
$tabs-tertiary-tab-font: rem-calc(14) $font-secondary;
$tabs-tertiary-control-items-margin: rem-calc(0 0 10);
$tabs-tertiary-tab-text-transform: uppercase;
$tabs-tertiary-controls-background-color: color(light);
$tabs-tertiary-tab-active-color: color(primary);
$tabs-tertiary-tab-active-after-border-color: color(primary);
$tabs-secondary-controls-background: transparent;
$tabs-secondary-content-background: transparent;
$tabs-secondary-controls-offset: rem-calc(20);
$tabs-secondary-controls-padding: rem-calc(0 0 0 $tabs-secondary-controls-offset);
$tabs-secondary-controls-padding--large: 0;
$tabs-secondary-controls-margin--large: 0;
$tabs-secondary-controls-width--large: 100%;
$tabs-secondary-tab-padding--large: rem-calc(40 95 35 0);
$tabs-secondary-control-items-divider-side-offset: 0;
$tabs-control-items-margin: 0;
$tabs-tertiary-tab-active-font-size: rem-calc(18);
$tabs-tertiary-tab-text-align: left;
$tabs-tertiary-tab-padding--large: rem-calc(15 0);
$tabs-tertiary-control-items-padding: 0;

@import "@lora/05-components/tabs";