@import "@lora/05-components/carousel/carousel-product";

@mixin master-components-carousel-product {
    .c-carousel {
        &.m-style-product.m-horizontal {
            @include breakpoint(medium down) {
                .c-carousel__arrows {
                    display: none;
                }
            }
        }
    }
}