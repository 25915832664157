$add-to-wishlist-icon-hover-color: color(dark);
$add-to-wishlist-icon-active-color: color(primary);
$add-to-wishlist-background: none;
$add-to-wishlist-icon-size: rem-calc(40);
$add-to-wishlist-icon-size--large: $add-to-wishlist-icon-size;
$add-to-wishlist-box-shadow: none;
// Small
$add-to-wishlist-small-icon-size: rem-calc(30);
$add-to-wishlist-small-padding: 0;

@import "@lora/05-components/add-to-wishlist";