$header-simple-search-field-placeholder-color: color(text-secondary);
$header-simple-search-field-placeholder-color--large: color(text-secondary);
$header-simple-search-field-height--large: rem-calc(110);
$header-simple-search-button-display: none;
$header-simple-search-results-background: color(light);
$header-simple-search-results-background--large: $header-simple-search-results-background;
$header-simple-search-search-button-active-box-shadow--large: rem-calc(0 10 14 0) rgba(color(dark), 0.15);
$header-simple-search-input-group-font-family: $font-secondary;
$header-simple-search-input-group-font-family--large: $font-secondary;
$header-simple-search-field-hover-box-shadow: none;
$header-simple-search-results-border-top: rem-calc(1) solid color(border);
$header-simple-search-label-font-family: $font-secondary;

@import "@lora/05-components/header/header-simple-search";