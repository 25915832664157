$footer-contacts-title-font--large: 300 #{rem-calc(14)} / 1.42 $font-primary;
$footer-contacts-description-font: 300 #{rem-calc(14)} / 1.42 $font-primary;
$footer-contacts-padding: rem-calc(35 0);
$footer-contacts-padding--large: rem-calc(0 0 75);
$footer-contacts-text-align--medium-down: left;
$footer-contacts-title-font: #{rem-calc(24)} / 1.16 $font-secondary;
$footer-contacts-description-flex-direction--medium-down: column;
$footer-contacts-link-background: color(light);
$footer-contacts-link-color: color(dark);
$footer-contacts-link-justify-content: space-between;
$footer-contacts-link-height: rem-calc(50);
$footer-contacts-link-radius: rem-calc(25);
$footer-contacts-link-font: 500 #{rem-calc(12)} / 4.16 $font-primary;
$footer-contacts-link-text-transform: uppercase;
$footer-contacts-title-margin: rem-calc(0 18 24);
$footer-contacts-title-margin--large: rem-calc(0 0 7);
$footer-contacts-link-store-icon-height: rem-calc(32);
$footer-contacts-link-store-icon-width: rem-calc(32);
$footer-contacts-link-phone-icon-height: rem-calc(32);
$footer-contacts-link-phone-icon-width: rem-calc(32);
$footer-contacts-link-first-child-margin: rem-calc(0 12 5);
$footer-contacts-link-last-child-margin: rem-calc(0 12 5);
$footer-contacts-link-padding: rem-calc(0 15);
$footer-contacts-link-flex-basis--medium-down: 100%;
$footer-contacts-link-simple-hover-color: color(primary-active);
$footer-contacts-description-link-color: color(primary);

@import "@lora/05-components/footer/footer-contacts";