$product-tile-name-font-size: rem-calc(12);
$product-tile-name-font-family: $font-tertiary;
$product-tile-name-line-height: 1.43;
$product-tile-name-color-visited: color(text);
$product-tile-name-text-transform: uppercase;
$product-tile-info-separator: none;
$product-tile-price-capacity-margin--large: rem-calc(15 0 0);
$product-tile-variations-width--large: 100%;
$product-tile-secondary-border: none;
$product-tile-secondary-border--large: $product-tile-secondary-border;
$product-tile-grid-list-align-items: flex-start;
$product-tile-badge-max-width: 75%;
$product-tile-badge-max-width--large: 50%;
$product-tile-badge-font: 500 #{rem-calc(12)} $font-primary;
$product-tile-grid-name-text-align: left;
$product-tile-grid-description-text-align: left;
$product-tile-grid-info-item-align-self: start;
$product-tile-description-line-height: 1.5;
$product-tile-price-font: 500 #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$product-tile-price-font--large: $product-tile-price-font;
$product-tile-name-color-hover: color(text-secondary);
$product-tile-description-margin: rem-calc(22) 0 0;
$product-tile-description-margin--large: rem-calc(12) 0 0;
$product-tile-rating-margin: 0 0 rem-calc(4);
$product-tile-variation-group-margin: rem-calc(8) 0;
$product-tile-add-bag-text-transform: uppercase;

$product-tile-wishlist-right: rem-calc(5);
$product-tile-wishlist-top: rem-calc(5);

$product-tile-badge-left: 0;
$product-tile-badge-top: rem-calc(8);
$product-tile-default-text-align: center;
$product-tile-info-item-align: center;
$product-tile-rating-align: center;

// Product actions
$product-tile-actions-margin: rem-calc(12 0 0);

@import "@lora/05-components/product/product-tile";