$content-reassurance-text-color: color(dark);
$content-reassurance-text-text-transform--large: uppercase;
$content-reassurance-text-font: #{rem-calc(14)} / 1.5 $font-primary;
$content-reassurance-text-font--large: 500 #{rem-calc(14)} / 1.15 $font-primary;
$content-reassurance-image-border-radius: 50%;
$content-reassurance-padding: rem-calc(30 0);
$content-reassurance-padding--large: $content-reassurance-padding;
$content-reassurance-fade: transparent;
$content-reassurance-columns: 8 of 12;
$content-reassurance-image-margin: rem-calc(0 20 0 0);
$content-reassurance-image-margin--large: $content-reassurance-image-margin;

@import "@lora/05-components/content/content-reassurance";