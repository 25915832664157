$locale-selector-select-arrow-size: rem-calc(6); // IE fix with image crop
$locale-selector-select-arrow-color: color(text-secondary);
$locale-selector-select-padding: rem-calc(0 10 0 0);
$locale-selector-select-color: color(dark);
$locale-selector-select-hover-color: color(text-secondary);
$locale-selector-select-secondary-hover-text-decoration: underline;
$locale-selector-item-link-color: color(dark);
$locale-selector-item-active-link-color: color(text-secondary);
$locale-selector-select-secondary-color: color(dark);
$locale-selector-select-secondary-arrow-color: color(dark);

@import "@lora/05-components/locale-selector";