$error-callout-title-font: #{rem-calc(24)} / rem-calc(28) $font-secondary;
$error-callout-title-font--large: #{rem-calc(42)} / 1 $font-secondary;
$error-callout-title-text-transform: none;
$error-callout-subtitle-text-transform: none;
$error-callout-subtitle-font: #{rem-calc(12)} / rem-calc(21) $font-primary;
$error-callout-subtitle-font--large: 100 #{rem-calc(14)} / rem-calc(20) $font-primary;
$error-callout-padding: rem-calc(180 25 40);
$error-callout-padding--large: rem-calc(100 135);
$error-callout-text-align: left;

@import "@lora/05-components/error-callout";