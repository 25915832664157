$header-hamburger-content-alignment--large: center;
$header-hamburger-item-link-transform: none;
$header-hamburger-item-link-color: color(dark);
$header-hamburger-button-width: rem-calc(55);
$header-hamburger-button-padding: rem-calc(12);
$header-hamburger-item-padding: rem-calc(0 16);
$header-hamburger-account-menu-icon-size: rem-calc(20);
$header-hamburger-storeslink-icon-size: rem-calc(22);
$header-hamburger-asset-link-may-we-help-you-icon-size: rem-calc(16);
$header-hamburger-asset-link-why-shop-online-icon-size: rem-calc(16);
$header-hamburger-button-icon-width: rem-calc(20);
$header-hamburger-button-icon-height: rem-calc(20);

//Item
$header-hamburger-item-font-size: rem-calc(14);
$header-hamburger-item-link-padding: rem-calc(15 0);

// Icons
$header-hamburger-icon-height: rem-calc(20);
$header-hamburger-icon-width: rem-calc(20);
$header-hamburger-icon-margin: rem-calc(0 19 5 0);
$header-hamburger-storeslink-icon: hamburger_store;
$header-hamburger-account-menu-icon: hamburger_user;
$header-hamburger-asset-link-may-we-help-you-icon: hamburger_questionmark;
$header-hamburger-asset-link-why-shop-online-icon: hamburger_gift;

// localization
$header-hamburger-localization-button-background: color(secondary);
$header-hamburger-localization-button-color: color(secondary-active);

@import "@lora/05-components/header/header-hamburger";