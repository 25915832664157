$progress-bar-height: rem-calc(7);
$progress-bar-background-color: color(secondary);
$progress-bar-value-background-color: color(primary);
$progress-bar-padding: rem-calc(0);
$progress-bar-border-radius: rem-calc(7.5);
$progress-bar-margin: rem-calc(10 4);
$progress-bar-before-background-color: color(secondary);
$progress-bar-after-background-color: transparent;
$progress-bar-before-icon-size: rem-calc(8);
$progress-bar-before-active-icon-size: rem-calc(8);
$progress-bar-before-icon: close;
$progress-bar-point-description-font: 400 #{rem-calc(12)} / #{rem-calc(18)} $font-primary;
$progress-bar-point-description-active-font: 400 #{rem-calc(12)} / #{rem-calc(18)} $font-primary;
$progress-bar-point-price-color: color(element);
$progress-bar-point-description-color: color(element);
$progress-bar-before-active-icon: tick-1;
$progress-bar-before-icon: cross-1;

@import "@lora/05-components/progress-bar";